@font-face {
	font-family: "CoFo";
	src: url("fonts/Cofo-regular.ttf");
	src: url("fonts/Cofo-regular.woff") format("woff"),
	}
html,
body {
	width: 100vw;
	overflow-x: hidden;
	font-family: -apple-system, BlinkMacSystemFont, 'CoFo', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	font-feature-settings: 'pnum' on, 'lnum' on;
	margin: 0;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	line-height: 133%;
	letter-spacing: -0.03em;
	scroll-behavior: smooth;
	transition-duration: 50ms;
}

.section1-background {
	position: absolute;
	top: 0;
	height: 100vh;
	width: 100vw;
	background-image: url(./images/background.jpg);
	opacity: 0.03;
	mix-blend-mode: multiply;
	background-size: cover;
	z-index: -5;
}

.section2-background {
	position: absolute;
	top: 100vh;
	height: 200vh;
	width: 100vw;
	background-image: url(./images/background.jpg);
	opacity: 0.03;
	mix-blend-mode: multiply;
	background-size: cover;
	z-index: -5;
}

.section3-background {
	position: absolute;
	top: 200vh;
	height: 100vh;
	width: 100vw;
	background-color: #141e37;
	background-size: cover;
	z-index: -5;
}
.App {
	height: 100vh;
	width: 100vw;
}

.App-careers {
	width: 100vw;
}

.App-logo {
	height: 40vmin;
}

#Menu {
	display: flex;
	flex-direction: row;
}

.MenuItem {
	padding: 0 0 0 15px;
	text-decoration: none;
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-weight: 600;
	color: rgba(20, 30, 55, 0.9);
}

.MenuItemActive{
	padding: 0 0 0 15px;
	text-decoration: none;
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-weight: 600;
	color: #6432ff;
}

.MenuItem:hover {
	text-decoration: none;
	color:#6432ff;
}

#cookie-link {
	color: whitesmoke
}

.phones-container{
	justify-content: center;
	display: flex;
	overflow: hidden;
}

.PhoneFrontImg {
	position: absolute;
	bottom: 0%;
	width: auto;
	height: 55%;
	justify-content: center;
	/*object-fit: contain;*/
}

a {
	text-decoration: none; /*do not erase it since it is used to remove underline in a objects*/
}

a.shown {
	color: blue;
}

.cardStackPage-title {
	height: 35vh;
	display: "flex";
}

@media only screen and (max-width: 1000px) {
	.PhoneFrontImg {
		position: absolute;
		bottom: 0%;
		width: auto;
		height: 100%;
		justify-content: center;	
	}
	.phones-container{
		justify-content: center;
		display: flex;
		position: relative;
	}
	.cardStackPage-title{
		height: 50vh;
		display: "flex"
	}
	.cardStackPage{
		height: 120vh;
	}
	.App-header {
		width: 100%;
		text-align: center;
		padding: 0 20px 0 20px;
	}
	.bankCardImg {
		width: 100%;
		height: 100%;
	}
	.dontworry-text {
		padding-right: 15vw;
	}
}
/* appear - on page load */
.fade-appear {
	opacity: 0;
	z-index: 1;
}
.fade-appear.fade-appear-active {
	opacity: 1;
	transition: opacity 600ms linear;
}
/* exit */
.fade-exit {
	opacity: 1;
}
.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 600ms linear;
}
.fade-exit-done {
	opacity: 0;
}


.Navbar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 18px;
	padding: 40px 50px 40px 50px;
	text-transform: uppercase;
	font-family: Raleway, -apple-system, sans-serif;
}

.App-header {
	text-align: center;
	line-height: 133%;
	font-size: 52px;
	color: #141e37;
	letter-spacing: -0.03em;
	font-feature-settings: "pnum" on, "lnum" on;
}
.Small-top-header-style {
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-size: 18px;
}
.Purple {
	color: #6432ff;
}

.Inline {
	display: inline;
}
.App-link {
	color: #09d3ac;
}

.thicc-text {
	font-weight: 600;
	line-height: 20px;
	font-size: 18px;
}
.thicc-text>span {
	margin-right: 20px;
}

.control-section-text {
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-weight: 600;
	letter-spacing: -0.03em;
	color: rgba(20, 30, 55, 0.9);
	font-size: 16px;
	line-height: 133%;
}

.dontworry{
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-weight: 600;
	color: rgba(20, 30, 55, 0.9);
}

.dontworry-text{
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-weight: 600;
	color: rgba(20, 30, 55, 0.9);
}

.dashboard-text{
	font-feature-settings: 'pnum' on, 'lnum' on;
	font-weight: 600;
	color: rgba(20, 30, 55, 0.9);
	font-size: 20px;
}

.takeControlPage-items{
	padding: 15vh 0;
	width: 75vw;
	margin: 0 auto;
}

.takeControlPage-items-1 {
	flex-direction: row;
}

.takeControlPage-items-2 {
	max-width: 50%;
}

@media only screen and (max-width: 1000px) {
	.takeControlPage-items{
		padding: 0 0;
		width: 75vw;
		margin: 0 auto;
	}
	.takeControlPage-items-1 {
		flex-direction: column;
	}

	.bankCardImgParent {
		/*filter: drop-shadow(0px 20px 50px rgba(0,0,0,0.2));
		-webkit-filter: drop-shadow(0px 20px 50px rgba(0,0,0,0.2));*/
		left: 15vw;
		
	}
	
}
.bankCardImgParent {
	/*filter: drop-shadow(0px 20px 50px rgba(0,0,0,0.2));
	-webkit-filter: drop-shadow(0px 20px 50px rgba(0,0,0,0.2));*/
	left: 31vw;
	
}


/*

	EMAIL BUTTON CSS

*/
:root {
    --main-color: rgb(100, 50, 255);
    --accent-color: #FEFEFE;
}
#singular-form button,
.container1>#singular-form>#input-container>input,
#singular-form button,
.container2>#singular-form>#input-container>input {
    line-height: 20px;
    font-size: 18px;
    font-weight: bold;
}

.container1,
.container2 {
    width: 10rem;
    height: 58px;
    padding: 10px;
    background-color: var(--main-color);
    text-align: center;
    border-radius: 3rem;
    overflow: hidden;
    transition: width .4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.container1>#singular-form,
.container2>#singular-form {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: --main-color;
}

.container1>#singular-form button,
.container2>#singular-form button  {
    width: 8rem;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
}

.container1>#singular-form>button#trigger,
.container2>#singular-form>button#trigger {
    padding: 0;
    width: 100%;
    color: var(--accent-color);
    background-color: transparent;
    z-index: 3;
}

.container1>#singular-form>#input-container,
.container2>#singular-form>#input-container {
    z-index: 2;
}

.container1>#singular-form>#input-container>input,
.container2>#singular-form>#input-container>input {
    display: inline-block;
    height: 100%;
    width: 100%;
    background-color: var(--main-color);
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0 26% 0 3%;
    opacity: 0;
    transform: scale(0);
    transition: all .4s ease .4s;
}

.container1>#singular-form>#input-container>button,
.container2>#singular-form>#input-container>button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: var(--accent-color);
    color: var(--main-color);
    opacity: 0;
    transform: scale(0);
    transition: all .4s ease .4s;
}

.container1>#singular-form>#success,
.container2>#singular-form>#success {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--accent-color);
    font-weight: bold;
    z-index: 1;
}

.container1>#singular-form>button#trigger,
.container1>#singular-form>#input-container,
.container1>#singular-form>#success,
.container2>#singular-form>button#trigger,
.container2>#singular-form>#input-container,
.container2>#singular-form>#success {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(0);
    opacity: 0;
}

.container1>#singular-form>button#trigger,
.container2>#singular-form>button#trigger {
    transition: all .4s ease;
}

.container1>#singular-form>#input-container,
.container2>#singular-form>#input-container {
    transform: scale(1);
    opacity: 1;
    transition: all .4s ease .4s;
}

.container1>#singular-form>#success,
.container2>#singular-form>#success {
    transition: all .2s ease .4s;
}

.container1>#singular-form>button#trigger.shown,
.container1>#singular-form>#input-container.shown,
.container1>#singular-form>#success.shown,
.container1>#singular-form>#input-container>button.shown,
.container1>#singular-form>#input-container>input.shown,
.container2>#singular-form>button#trigger.shown,
.container2>#singular-form>#input-container.shown,
.container2>#singular-form>#success.shown,
.container2>#singular-form>#input-container>button.shown,
.container2>#singular-form>#input-container>input.shown {
    transform: scale(1);
    opacity: 1;
}

.container1>#singular-form>#input-container>input.shown,
.container2>#singular-form>#input-container>input.shown {
	color: var(--accent-color);
}

.container1>#singular-form>#input-container>input.shown::placeholder,
.container2>#singular-form>#input-container>input.shown::placeholder {
	color: rgba(255, 255, 255, 0.7);
	opacity: 1;
}

.column {
	float: left;
	width: 25%;
	padding: 5vw;
	padding-top: 10vh;
	padding-bottom: 0vh;
	height: auto;
}

.column2 {
	float: left;
	width: 25%;
	padding: 5vw;
	padding-top: 10vh;
	padding-bottom: 10vh;
	height: auto;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {}


/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
	.column {
		float: left;
		width: 50%;
		padding: 5vw;
		padding-top: 10vh;
		padding-bottom: 0vh;
		height: auto;
	}
	.column2 {
		float: left;
		width: 50%;
		padding: 5vw;
		padding-top: 10vh;
		padding-bottom: 10vh;
		height: auto;
	}
	.row {
		text-align: center;
	}
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
	.column {
		float: left;
		width: 50%;
		padding: 5vw;
		padding-top: 10vh;
		padding-bottom: 0vh;
		height: auto;
	}
	.column2 {
		float: left;
		width: 50%;
		padding: 5vw;
		padding-top: 10vh;
		padding-bottom: 10vh;
		height: auto;
	}
	.row {
		text-align: center;
	}
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
	.column {
		float: left;
		width: 50%;
		padding: 5vw;
		padding-top: 10vh;
		padding-bottom: 0vh;
		height: auto;
	}
	.column2 {
		float: left;
		width: 50%;
		padding: 5vw;
		padding-top: 10vh;
		padding-bottom: 10vh;
		height: auto;
	}
	.row {
		text-align: center;
	}
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
	.column {
		float: left;
		width: 100%;
		padding: 5vw;
		padding-top: 10vh;
		padding-bottom: 0vh;
		height: auto;
	}
	.column2 {
		float: left;
		width: 100%;
		padding: 5vw;
		padding-top: 10vh;
		height: auto;
	}
	.row {
		text-align: center;
	}
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
	.column {
		float: left;
		width: 100%;
		padding: 5vw;
		padding-top: 10vh;
		padding-bottom: 0vh;
		height: auto;
	}
	.column2 {
		float: left;
		width: 100%;
		padding: 5vw;
		padding-top: 10vh;
		height: auto;
	}
	.row {
		text-align: center;
	}
}

.column a {
	color: #fff;
}

.column2 a {
	color: #fff;
}

.row {
	content: "";
	display: table;
	clear: both;
	width: 100vw;
	background-color: whitesmoke;
}

.box {
	box-sizing: border-box;
}

.fullimg {
	width:100vw;
	height: 100vh;
}

.fullimg::before {
	background-image: url(./images/background.jpg);
	background-position: center;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width:100vw;
	height: 100vh;
	z-index: -5;
	mix-blend-mode: multiply;
	opacity: 0.03;
	background-size: cover;
	transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
}

.content {
	position:absolute; 
    width:100%; 
    top:100%;
}

.imagecaption {
	position: absolute;
	left: 0;
	top: 40%;
	width: 100%;
	text-align: center;
}

.column img {
	max-width: 200px;
	max-height: 200px;
	box-shadow: 10px 10px 10px grey ;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.column2 img {
	max-width: 200px;
	max-height: 200px;
	box-shadow: 10px 10px 10px grey ;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.column img:hover {
	opacity: 0.5;
}

.column2 img:hover {
	opacity: 0.5;
}

.scroll img {
	width: 90px;
	height: 90px;
}

.column h2 {
	padding-top: 20px;
	text-transform: uppercase;
	color: #141e37;
	font-family: Roboto;
}

.column p {
	padding-top: 15px;
	font-weight: 800;
	color: rgb(100, 50, 255);
}

.column2 h2 {
	padding-top: 20px;
	text-transform: uppercase;
	color: #141e37;
	font-family: Roboto;
}

.column2 p {
	padding-top: 10px;
	font-weight: 800;
	color: rgb(100, 50, 255);
}

.row .teamheader {
	text-align: center;
	padding: 60px 0 0 0;
	font-size: 48px;
	color: #141e37;
	font-family: Roboto;
	font-weight: 600;
}